<template>
    <div class="col-filter-days">
        <div class="add-filter">
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle btn-add-filters" type="button" data-toggle="dropdown" id="dropdownMenuLink" v-if="!isMobile">
                    <IconPlus />
                    {{$t("FilterDays.TextButton")}}
                </button>
                <button :class="`btn btn-secondary dropdown-toggle  ${filtersNumber != 0 ? '--active-add-filter': 'btn-add-filters'}`" type="button" v-else @click="toogleFiltro()">

                    <IconFilter />
                    {{$t('FilterDays.Filter')}}
                    <div class="filters-number" v-if="filtersNumber > 0">
                        <span>
                            {{
                               filtersNumber
                            }}
                        </span>
                    </div>
                </button>
                <ul class="dropdown-menu dropdown-menu-filter-resource-loading dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    <li
                        v-for="(item, index) in menuItems"
                        :class="[
                            'dropdown-item',
                            item.children && 'dropdown-submenu',
                            !item.children && 'dropdown-submenu time-picker',
                        ]"
                        :key="index"

                    >
                        <a class="item" tabindex="-1" href="" @click="filterTerm = ''" v-if="item.name == 'Centros de Trabalho'">{{$t('FilterDays.Workcenter')}} <IconSetaDropdown class="icon-dropdown"/></a>

                        <DateTimePicker
                            v-if="item.name === 'Periodo'"
                            datepicker-id="1"
                            v-model="datepickerValue"
                            position="left"
                            ref="dateTimePickerRefDesktop"
                            :range="true"
                            :blockDates="blockDates"
                            :weekDays="setWeekDays()"
                            :month-names="setMonths()"
                            :txt-cancel-button="$t('Datepicker.Cancel')"
                            :txt-save-button="$t('Datepicker.Save')"
                            :txt-time="$t('Datepicker.Time')"
                            :txt-date="$t('Datepicker.Date')"
                            @input="(value) => updateValue(value, item.type, false)"
                        >
                            <template v-slot:input>
                                <a class="item" href="" @click="handlePeriodItem('1')" >{{$t('FilterDays.Period')}} <IconSetaDropdown class="icon-dropdown"/></a>
                            </template>
                        </DateTimePicker>

                        <a class="item" tabindex="-1" href="" @click="filterTerm = ''" v-if="item.name == 'Recursos'">{{$t('FilterDays.Resources')}} <IconSetaDropdown class="icon-dropdown"/></a>

                        <ul :class="['dropdown-menu dropdown-sub-menu', (listagemFiltros.date.dataFim  || listagemFiltros.date.dataInicio) && 'left']" v-if="item.children">
                            <div class="input-search">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="$t('FilterDays.TypeHere')"
                                    v-model="filterTerm"
                                >
                            </div>
                            <li
                                v-for="(child, index) in item.children.filter(item => {
                                    return filterTerm != '' ? item.name.toLowerCase().includes(filterTerm.toLowerCase()) : item
                                })"
                                :key="index"
                                @mousedown="adicionaFiltro(item.type, child.name ,child.id, false)"
                            >
                                <a tabindex="-1" href="#" >{{child.name}}</a>
                            </li>
                        </ul>

                    </li>
                </ul>
            </div>
        </div>
        <div class="filter-range grid-container" v-if="!listagemFiltros.date.dataFim  || !listagemFiltros.date.dataInicio">
            <b-button :class="['btn-range-filter left grid-item', activeDay7 && 'active']" @click="updateDay(7)" id="tooltip-7-dias">
                <span>{{$t("FilterDays.7days")}}</span>
            </b-button>
            <b-tooltip
                custom-class="tooltip-filter-days"
                target="tooltip-7-dias"
                triggers="hover"
                placement="bottomright"
            >
                <div class="info-tooltip-filter-days">
                    {{ hasMinDate ? $t("FilterDays.7daysInfo") : $t("FilterDays.7daysInfoOee") }}
                </div>
            </b-tooltip>

            <b-button :class="['btn-range-filter middle grid-item', activeDay45 && 'active']" @click="updateDay(45)" id="tooltip-45-dias">
                <span>{{$t("FilterDays.45days")}}</span>
            </b-button>
            <b-tooltip
                custom-class="tooltip-filter-days"
                target="tooltip-45-dias"
                triggers="hover"
                placement="bottom"
            >
                <div class="info-tooltip-filter-days">
                    {{ hasMinDate ? $t("FilterDays.45daysInfo") : $t("FilterDays.45daysInfoOee") }}
                </div>
            </b-tooltip>

            <b-button :class="['btn-range-filter right grid-item', activeDay60 && 'active']" @click="updateDay(60)"  id="tooltip-60-dias">
                <span>{{$t("FilterDays.60days")}}</span>
            </b-button>
            <b-tooltip
                custom-class="tooltip-filter-days"
                target="tooltip-60-dias"
                triggers="hover"
                placement="bottomleft"
            >
                <div class="info-tooltip-filter-days">
                    {{ hasMinDate ? $t("FilterDays.60daysInfo") : $t("FilterDays.60daysInfoOee") }}
                </div>
            </b-tooltip>
        </div>

        <div class="filter-mobile d-none" v-if="filterActive">
            <div class="header-card-filter">
                <div class="col-title">
                    <span class="title-card-filter">{{$t('FilterDays.Filter')}}</span>
                    <IconClose @click="toogleFiltro()"/>
                </div>

                <div class="input-search">
                    <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('FilterDays.Search')"
                        v-model="filterTerm"
                     >
                    <IconSearch/>
                </div>
            </div>
            <div class="itens-card-filter">
                <DateTimePicker
                    datepicker-id="2"
                    v-model="datepickerValue"
                    position="left"
                    ref="dateTimePickerRefMobile"
                    :range="true"
                    :blockDates="blockDates"
                    :weekDays="setWeekDays()"
                    :month-names="setMonths()"
                    :txt-cancel-button="$t('Datepicker.Cancel')"
                    :txt-save-button="$t('Datepicker.Save')"
                    :txt-time="$t('Datepicker.Time')"
                    :txt-date="$t('Datepicker.Date')"
                    @input="(value) => updateValue(value, 'date')"
                >
                    <template v-slot:input>
                        <button class="btn btn-secondary btn-color btn-period">
                            <span>{{$t('FilterDays.SelectPeriod')}}</span>
                        </button>
                    </template>
                </DateTimePicker>

                <div class="filter-itens">
                    <div
                        v-for="(item, index) in menuItems"
                        :key="index"

                    >
                        <span class="title-item" v-if="item.name == 'Centros de Trabalho'">{{$t('FilterDays.Workcenter')}}</span>
                        <span class="title-item" v-if="item.name == 'Recursos'">{{$t('FilterDays.Resources')}}</span>
                        <button
                            v-for="(child, index) in item.children ? item.children.filter(item => {
                                return filterTerm != '' ? item.name.toLowerCase().includes(filterTerm.toLowerCase()) : item
                            }) : []"
                            :key="index"
                            class="col-sub-item"
                            @click="adicionaFiltro(item.type, child.name ,child.id)"

                        >
                            {{child.name}}
                        </button>
                    </div>
                </div>

            </div>

            <div class="footer-buttons ">
                <button class="btn btn-secondary btn-clear" @click="limparFiltros()">
                    <span>{{$t('FilterDays.Clean')}}</span>
                </button>

                <button  class="btn btn-secondary btn-color btn-apply" @click="aplicaFiltro()">
                    <span>{{$t('FilterDays.Submit')}}</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {
  BButton,
  BForm,
  BRow,
  BCol,
  BTooltip
  } from 'bootstrap-vue'
import IconPlus from '../../../assets/images/pages/add-icon.svg'
import IconFilter from '../../../assets/images/pages/filter-icon.svg'
import IconSearch from '../../../assets/images/pages/search-icon.svg'
import IconClose from '../../../assets/images/pages/close-icon.svg'
import IconSetaDropdown from '../../../assets/images/pages/seta-dropdown-icon.svg'
import DateTimePicker from 'components-code/date-time-picker/DatePickerFull.vue'
import moment from 'moment';

export default {
    components: {
        BButton,
        IconPlus,
        IconSetaDropdown,
        BForm,
        BRow,
        BCol,
        IconFilter,
        IconSearch,
        IconClose,
        BTooltip,
        DateTimePicker
    },
    props: ["listagemFiltros", "urlResourcesLoading", "hasMinDate"],
    data(){
        return{
            listagemFiltrosLocal: {
                date:{
                    dataInicio:'',
                    dataFim:''
                },
                resource:[],
                workcenter:[]
            },
            defaultDate: this.hasMinDate ?  [new Date(), new Date(new Date().setDate(new Date().getDate() + 7))] : [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
            datepickerValue: this.hasMinDate ? [new Date(), new Date(new Date().setDate(new Date().getDate() + 7))] : [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
            filtersNumber: 0,
            filterTerm:'',
            filterDays: 7,
            activeDay7:true,
            activeDay45:false,
            activeDay60:false,
            menuItems: [
                {name: 'Centros de Trabalho',
                type:'workcenter',
                children: []
                },
                {name: 'Periodo',
                 type:'date',},
                {name: 'Recursos',
                 type:'resource',
                children: []
                }
            ],
            dataFiltro:'',
            filterActive: false,
            urlResourcesLoadingLocal:'',
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
        }
    },
    watch:{
        urlResourcesLoading(v){
            if(v == '&filterDays=7'){
                this.activeDay7 = true;
                this.activeDay45 = false;
                this.activeDay60 = false;
            }
        },

        "listagemFiltros.resource"(v) {
            this.filtersNumber = (this.listagemFiltros.date.dataInicio != "" ? 1 : 0)
                                + this.listagemFiltros.workcenter.length
                                + this.listagemFiltros.resource.length
        },
        "listagemFiltros.workcenter"(v) {
            this.filtersNumber = (this.listagemFiltros.date.dataInicio != "" ? 1 : 0)
                                + this.listagemFiltros.workcenter.length
                                + this.listagemFiltros.resource.length
        },
        "listagemFiltros.date.dataInicio"(v) {
            this.filtersNumber = (this.listagemFiltros.date.dataInicio != "" ? 1 : 0)
                                + this.listagemFiltros.workcenter.length
                                + this.listagemFiltros.resource.length
        }
    },
    methods: {
        handleDatepickerChange(value) {
            this.datepickerValue = value;
        },
        blockDates(date) {
            const currentDate = moment(moment().format('YYYY-MM-DD'));
            const inspected = moment(moment(new Date(date)).format('YYYY-MM-DD'));

            if(this.hasMinDate) return inspected < currentDate;
            return inspected > currentDate;
        },
        setWeekDays() {
            const weekdays = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday'
            ];

            return weekdays.map((day) => ({
                name: this.$t(`Datepicker.Weekdays.Full.${day}`),
                short: this.$t(`Datepicker.Weekdays.Short.${day}`)
            }));
        },
        setMonths()  {
            const months = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ];

            return months.map((month) => ({
                name: this.$t(`Datepicker.Months.Full.${month}`),
                short: this.$t(`Datepicker.Months.Short.${month}`)
            }));
        },
        handlePeriodItem() {
            this.filterTerm = '';
            document.querySelector('#handler-1').click();
            document.querySelector('.btn-add-filters').click();
        },
        getDataContext() {
            this.$http.get(`/api/${this.prefix}/dashboards/context?site_guid=${'A860E63D-522D-4B6F-9687-DFBEFAE3C38E'}`)
            .then(res => {
                this.menuItems[0].children = res.data.workcenters;
                this.menuItems[2].children = res.data.resources;
            }).catch(error => console.log(error));
        },
        updateDay(value){

            this.activeDay7 = false
            this.activeDay45 = false
            this.activeDay60 = false

            if(value == 7){
                this.activeDay7 = true
            }
            else if(value == 45){
                this.activeDay45 = true
            }
            else{
                this.activeDay60 = true
            }

            this.filterDays = value
            this.verificaURL();
        },
        limparFiltros(){
            this.listagemFiltros = {
                date:{
                    dataInicio:'',
                    dataFim:''
                },
                resource:[],
                workcenter:[]
            }


            this.$emit('update:listagemFiltros', this.listagemFiltros)
            this.verificaURL()
            this.toogleFiltro();
        },
        toogleFiltro(){
            this.filterActive = !this.filterActive
            this.listagemFiltrosLocal = {
                date:{
                    dataInicio:'',
                    dataFim:''
                },
                resource:[],
                workcenter:[]
            }
        },
    	adicionaFiltro(type,filtro,id_filtro, mobile = true){
            let body = {
                id: id_filtro,
                name: filtro
            }

            if(mobile) {
                if(type == 'date'){
                    filtro.forEach((date, i) => filtro[i] = moment(date).format('YYYY-MM-DD'))
                    this.listagemFiltrosLocal['date']['dataInicio'] = filtro[0];
                    this.listagemFiltrosLocal['date']['dataFim'] = filtro[1];
                }
                else if(type == 'resource'){
                    if(!this.listagemFiltrosLocal.resource.find((item) => item.id == id_filtro)) {
                        this.listagemFiltrosLocal.resource.push(body)
                    }
                }
                else {
                    if(!this.listagemFiltrosLocal.workcenter.find((item) => item.id == id_filtro)) {
                        this.listagemFiltrosLocal.workcenter.push(body)
                    }
                }
            } else {
                if(type == 'date'){
                    filtro.forEach((date, i) => filtro[i] = moment(date).format('YYYY-MM-DD'))
                    this.listagemFiltrosLocal['date']['dataInicio'] = filtro[0];
                    this.listagemFiltrosLocal['date']['dataFim'] = filtro[1];
                }
                else if(type == 'resource'){
                    if(!this.listagemFiltros.resource.find((item) => item.id == id_filtro)) {
                        this.listagemFiltrosLocal.resource.push(body)
                    }
                }
                else {
                    if(!this.listagemFiltros.workcenter.find((item) => item.id == id_filtro)) {
                        this.listagemFiltrosLocal.workcenter.push(body)
                    }
                }
                this.aplicaFiltro();
            }
        },
        aplicaFiltro(){
            this.listagemFiltros.date = this.listagemFiltrosLocal.date;
            this.listagemFiltros.workcenter = [...this.listagemFiltros.workcenter, ...this.listagemFiltrosLocal.workcenter]
            this.listagemFiltros.resource = [...this.listagemFiltros.resource, ...this.listagemFiltrosLocal.resource];
            this.datepickerValue = [...this.defaultDate];
            this.$emit('update:listagemFiltros', this.listagemFiltros);
            this.verificaURL();
            this.toogleFiltro();
        },
        verificaURL(){
            this.urlResourcesLoadingLocal =  `&filterDays=${this.filterDays}`

            if (this.listagemFiltros.workcenter.length > 0){

                this.urlResourcesLoadingLocal += `&workcenters_ids=${this.listagemFiltros.workcenter.map(item => item.id)}`
            }
            if (this.listagemFiltros.resource.length > 0){
                this.urlResourcesLoadingLocal += `&resources_ids=${this.listagemFiltros.resource.map(item => item.id)}`
            }
            if(this.listagemFiltros.date.dataInicio){
                this.urlResourcesLoadingLocal += `&start_date=${this.listagemFiltros.date.dataInicio}`
            }
            if(this.listagemFiltros.date.dataFim){
                this.urlResourcesLoadingLocal += `&end_date=${this.listagemFiltros.date.dataFim}`
            }

            this.$emit('update:urlResourcesLoading', this.urlResourcesLoadingLocal)
            this.$emit('refreshResourcesLoading')
        },
        updateValue(value, type, mobile = true){
            this.datepickerValue = value;
            this.adicionaFiltro(type,value, null, mobile);
        },
    },
    computed:{
        isMobile(){
            return window.innerWidth < 480
        }
    },
    mounted() {
        this.getDataContext();
        this.verificaURL();
        $('.dropdown-submenu a.item').on("click", function(e){
            const oldDropdown = $(this).parent().parent().find('.dropdown-submenu-visible')
            oldDropdown.toggle()
            oldDropdown.removeClass('dropdown-submenu-visible')

            const newDropdown = $(this).next('ul')
            newDropdown.addClass('dropdown-submenu-visible');

            newDropdown.toggle()
            e.stopPropagation();
            e.preventDefault();
        });
        $('.dropdown-time-picker').on("click", function(e){
            const oldDropdown = $(this).parent().parent().find('.dropdown-submenu-visible')
            oldDropdown.toggle()
            oldDropdown.removeClass('dropdown-submenu-visible')
        });


        $('#dropdownMenuLink').on("click", function(e){
            const oldDropdown = $(this).parent().parent().find('.dropdown-submenu-visible')
            oldDropdown.toggle()
            oldDropdown.removeClass('dropdown-submenu-visible')
        });

        $('.dropdown-submenu.time-picker .item').on("click", function(e){
            $(this).parent().find('.form-control').click();
        });

        $('.dropdown-sub-menu').on('click', function (e) {
            e.stopPropagation();
        });

    }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.dropdown-menu {
    margin: 3px 0 !important;
    padding: 0;
    z-index: 100;
    .dropdown-sub-menu {
        width: 185px;
        margin: 0 1px !important;
        padding: 0 !important;
        border-radius: 6px;
        padding-top: 8px !important;
        max-height: 336px;
        &::-webkit-scrollbar {
        width: 5px;               /* width of the entire scrollbar */
        border-radius: 50%;
        }

        &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #FBEEE8;        /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
        background-color: #CFC4BE;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        }
        overflow: auto;
        li {
            a {
                padding: 0;

            }
        }
  }
}
.dropdown-menu-filter-resource-loading {
    width: 180px;
    margin: 0 8px;
    border-radius: 6px;
    & > .form-control {
        padding: 12px 30px 12px 21px;
        background: #fff;
        &:active {
            background: #FFEDE2;
        }
    }
    .dropdown-item {
        padding: 0;
        a {
            padding: 12px 30px 12px 20px;
           svg {
                position: absolute;
                right: 15px;
            }
        }
        &:active {
            a {
                background: #FFEDE2;
            }
            background: #FFEDE2;
        }
    }
}

@media (max-width: 650px) {
    .filters-number {
        background-color: #fff;
        border-radius: 50%;
        margin-left: 10px;
        height: 18px;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            color: #974900;
        }
    }

    .--active-add-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #974900 !important;
        color: #fff !important;
        background-color: #974900 !important;
        padding: 4px 10px;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        svg{
            margin-right: 6px;
            width: 12px;
            height: 12px;

            path {
                fill: #fff !important;
            }
        }
        &::after{
            display: none;
        }

        &:hover{
            background-color: #974900 !important;
            color: #ffff !important;
            svg{
                path{
                    stroke: #ffff !important;
                }
            }

        }
        &:focus{
            background-color: #FBEEE8 !important;
            color: #974900 !important;
            svg{
                path{
                    stroke: #974900 !important;
                }
            }

        }

    }


    body .flatpickr-calendar {
     left: 16px !important;
    }
    .col-filter-days{
        flex-direction: column;
        width: 100%;
        .add-filter{
             margin: 0 !important;
            .dropdown{
                button{
                    width: 100%;
                    margin-bottom: 8px;
                }
            }

        }
        .filter-range{
            &.grid-container{
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
        .filter-mobile{
            display: block !important;
            padding: 16px;
            position: fixed;
            z-index: 99999;
            width: 100%;
            height: 100%;
            background-color: #ffff;
            top: 0;
            left: 0;
            box-sizing: border-box;

            .header-card-filter{
                .col-title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title-card-filter{
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 26px;
                        color: #4C4541;

                    }
                }

                .input-search{
                    margin: 12px 0;
                    position: relative !important;

                    svg{
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%);
                        position: absolute !important;
                    }
                }
            }
            .itens-card-filter{
                height: calc(100vh - 178px);
                overflow:scroll;
                .date-picker{
                    .form-group{
                        .form-control{
                            border: none;
                            background-color: transparent;
                            position: absolute;
                            top: 104px;
                            width: 90%;
                            height: 30px;
                            color: transparent !important;
                        }
                        svg{
                            display: none;
                        }
                    }
                }
                .btn-period{
                    position: relative;
                    width: 100%;
                    padding-top: 4px !important;
                    padding-bottom: 4px !important;
                    margin-bottom: 12px;
                    span{
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
                .filter-itens{

                    .title-item{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 26px;
                        color: #4C4541;
                    }
                    .col-sub-item{
                        padding: 18px 16px 18px 32px;
                        border-radius: 6px;
                        text-align: left;
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #998F8A;

                        &:focus{
                            background-color: #FFDBC4 !important;
                            color: #974900 !important;

                        }

                    }
                }
            }


            .footer-buttons{
                padding-top: 16px !important;
                width: calc(100% - 32px);
                display: grid;
                position: absolute;
                bottom:16px;
                box-sizing: border-box;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                background-color: #ffff;

                .btn-clear{
                    width: 100%;
                    color: #974900 !important;
                    background-color: transparent !important;
                    border-color: transparent !important;
                    box-shadow: none;
                    span{
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 20px;
                    }
                    &:focus{
                        background-color: #ffede2 !important;
                    }
                }
                .btn-apply{
                    width: 100%;
                    span{
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 20px;
                    }
                }

            }
        }
    }
}

@media (max-width: 576px) and (min-width: 481px){
    body .flatpickr-calendar {
        top: 295px !important;
        left: 100px !important;
    }
}

@media (max-width: 735px) and (min-width: 576px){
    body .flatpickr-calendar {
        top: 248px !important;
        left: 150px !important;
    }
}

.btn-color{
    background-color: #974900 !important;
    color: #fff !important;
    border-radius: 5px;
    border: 1px solid #974900 !important;
    &:focus{
        background-color:  #9f5714 !important;
    }
}

.flatpickr-calendar{
    .flatpickr-day {
        &.startRange, &.endRange{
            background-color: #974900 !important;
            border-color: #974900 !important;
            color: #ffff !important;
        }
        &.inRange{
            box-shadow: -5px 0 0 #ECE0DB, 5px 0 0 #ECE0DB;
            background-color: #ECE0DB !important;
            border-color: #ECE0DB !important;
            color: #6e6b7b !important;
            &:hover{
               box-shadow: -5px 0 0 #ECE0DB, 5px 0 0 #ECE0DB;
            }
        }
        &.today{
            border-color: #974900;
        }
    }
}
.dropdown-menu-filter-resource-loading{
    .dropdown-submenu {
        &.time-picker{
            position: relative;
            .dropdown-time-picker{
                width: 100%;
                position: absolute;
                top: -50px;
                right: 307px;
                padding: 0;
                .form-group{
                    .form-control{
                        cursor: pointer !important;
                        border: transparent;
                        background-color: transparent;
                        opacity: 0 !important;
                    }
                    svg{
                        display: none;
                    }
                }
            }
        }
        .dropdown-menu {
            top: 0;
            left: 100%;
            margin-top: -1px;
            transform: scale(1, 1) !important;
            padding: 20px 0;
            min-width: 185px;
            &.dropdown-sub-menu.left{
                left: -100% !important;
            }
            .input-search{
                padding: 12px 21px;
                .form-control{
                    border: 1px solid #CFC4BE;
                    font: normal 400 14px/20px initial;
                    color: #CFC4BE;
                    padding: 6px 14px ;
                    &::placeholder {
                        font: normal 400 14px/20px initial;
                    }
                }
            }
            li{
                padding: 12px 21px;
                a{
                    color: #9F8D83 ;
                    background-color: transparent !important;

                }
                &:hover, &:focus{
                    a{
                        color:#974900 ;
                    }

                }
            }
        }
    }
}



.col-filter-days{
    display: flex;
    .add-filter{
        margin-right: 14px;
        .btn-add-filters{

            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #974900 !important;
            background-color: transparent !important;
            padding: 4px 10px;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #974900 !important;

            svg{
                margin-right: 6px;
                width: 12px;
                height: 12px;
            }

            &::after{
                display: none;
            }

            &:hover{
                background-color: #974900 !important;
                color: #ffff !important;
                svg{
                    path{
                        stroke: #ffff !important;
                    }
                }

            }
            &:focus{
                background-color: #FBEEE8 !important;
                color: #974900 !important;
                svg{
                    path{
                        stroke: #974900 !important;
                    }
                }

            }
        }
        .dropdown-menu{

            &.show{
                li{
                    .item{
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #9F8D83 !important;
                        background-color: transparent;
                        svg{
                            margin-left: 10px;
                        }

                    }
                    &:hover{
                            background-color: #FBEEE8;
                            svg{
                                path{
                                    stroke: #974900;
                                }
                            }
                            .item{
                                background-color: #FBEEE8;
                                color: #974900 !important;
                            }
                        }
                }
            }
        }

    }
    .filter-range{
        .btn-range-filter{
            border: 1px solid #974900 !important;
            background-color: transparent !important;
            padding: 4px 16px;
            span{
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                color: #974900;
            }
            &.left{
                border-radius: 6px 0px 0px 6px;
            }
            &.middle{
                border-radius: 0;
                border-left: 0px !important;
                border-right: 0px !important;
            }
            &.right{
                border-radius: 0px 6px 6px 0px;
            }
            &:focus, &.active{
                background-color: #974900 !important;
                span{
                    color: #ffff !important;
                }
            }
            &:hover{
                background-color: #FFDBC4 !important;
                span{
                    color: #974900 !important;
                }

            }
        }
    }
}

.tooltip-filter-days {
    //não adicione padding aqui e só estilize as classes especificas se possível
  margin: 0;
  .tooltip-inner {
      padding: 0;
      max-width: 100%;
      min-width: 1%;
      border-radius: 6px;
      overflow: hidden;

      .info-tooltip-filter-days {
          max-width: 100%;
          padding: 8px 8px;
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          letter-spacing: 0.16px;
      }
  }

  .arrow {
      &::before {
          border-top-color: #323232;
          border-bottom-color: #323232;
      }
  }
}

</style>
